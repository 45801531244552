@tailwind base;
@tailwind components;
@tailwind utilities;


/* Import both Arizonia and MuseoModerno fonts from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Arizonia&family=Englebert&family=MuseoModerno:ital,wght@0,100..900;1,100..900&family=Vibur&family=Spirax&family=Teko:wght@300..700&display=swap');

html {
  font: 400 16px 'MuseoModerno', sans-serif;
  overflow-x: hidden;
}

body {
  background-image: url('images/body-bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'MuseoModerno', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: #333;
  overflow-x: hidden;
}

p{
  font-family: 'MuseoModerno', sans-serif;
}

 h2, h3, h4, h5, h6 {
  font-family: 'Teko';
  font-style: italic; /* Optional: If you want headings to be italicized */
  font-weight: 700; /* Adjust if necessary */
  line-height: 1.2;
  color: #333; /* You can adjust the color as needed */
}

/* Example for h1 size */
h1 {
  font-size: xxx-large;
  font-family: 'Vibur', system-ui;
  font-size: 2em; /* Adjust the size as needed */
  background-image: linear-gradient(to right, #ff7e5f, #feb47b); /* Gradient colors */
  -webkit-background-clip: text; /* Clip background to text (WebKit browsers) */
  background-clip: text; /* Clip background to text (other browsers) */
  color: transparent; /* Make text color transparent to show gradient */
}


@media (min-width: 768px) {
  h1, .h1 {
    font-size: 2.25rem;
  }
}

h2, .h2 {
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  h2, .h2 {
    font-size: 1.75rem;
  }
}

h3, .h3 {
  font-size: 1.25rem;
}

@media (min-width: 768px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}

h4, .h4 {
  font-size: 1rem;
}

h5, .h5 {
  font-size: 0.875rem;
}

h6, .h6 {
  font-size: 0.75rem;
}


.btn {
  display: inline-block;
  height: 52px;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  transition: all 0.2s;
}

.btn-primary {
  cursor: pointer;
  border-radius: 50px;
  background-image: linear-gradient(to top left, #fc7f6f, #fdbf7e);
  color: #fff;
  text-align: center;
  font-weight: 500;
  transition: all 0.2s;
}

.btn-primary:hover {
  box-shadow: 0px 5px 30px rgba(252, 167, 111, 0.5);
}

.btn-outline-primary {
  position: relative;
  border-radius: 50px;
  border: 2px solid #333;
  background-color: transparent;
  color: #333;
  text-align: center;
  font-weight: 500;
  padding: 7px 8px;
  transition: all 0.2s;
}

.btn-outline-primary::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-radius: inherit;
  background-image: linear-gradient(to top left, #fc7f6f, #fdbf7e);
  opacity: 0;
  transition: inherit;
}

.btn-outline-primary:hover {
  background-color: #fc7f6f;
  color: #fff;
  box-shadow: 0px 5px 30px rgba(252, 167, 111, 0.5);
}

.btn-outline-primary:hover::before {
  opacity: 1;
}

.btn-outline-white {
  border-radius: 50px;
  background-color: transparent;
  color: #333;
  text-align: center;
  font-weight: 500;
}

.btn-white {
  min-width: 150px;
  border-radius: 50px;
  border: 2px solid #fff;
  background-color: #fff;
  color: #333;
  text-align: center;
  font-weight: 500;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.btn-white:hover {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.btn-sm {
  height: 42px;
  line-height: 24px;
}

/* Navbar Toggler */
input#nav-toggle:checked ~ label#show-button {
    display: none;
  }
  
  input#nav-toggle:checked ~ label#hide-button {
    display: flex;
  }
  
  input#nav-toggle:checked ~ #nav-menu {
    display: block;
  }
  
  /* Header */
  .header {
    position: sticky;
    top: 0;
    z-index: 50;
    background-color: #fff;
    padding: 1.5rem 0;
    transition: all 0.3s;
  }
  
  .header-sticky {
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Navbar Items */
  .navbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-family: 'MuseoModerno', sans-serif; /* Updated font-family */
  }
  
  .navbar-brand {
    font-size: 24px;
    font-weight: 600;
    color: #333;
  }
  
  .navbar-brand image {
    max-height: 100%;
    max-width: 100%;
  }
  
  .navbar-nav {
    text-align: center;
  }
  
  .nav-link {
    display: block;
    padding: 0.75rem 0.5rem;
    font-size: 15px;
    color: #333;
    transition: color 0.2s;
    font-family: 'MuseoModerno';
  }
  
  .nav-link:hover, .nav-link.active {
    color: #fc7f6f;
    position: relative;
  }
  
  .nav-link:hover::after, .nav-link.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0.5rem;
    right: 0.5rem;
    height: 0.25rem;
    background-image: linear-gradient(to top left, #fc7f6f, #fc7f6f);
  }
  
  .nav-dropdown {
    margin-right: 0;
  }
  
  .nav-dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    min-width: 180px;
    margin-top: 1rem;
    margin-left: 0.25rem;
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #337ab7;
    border-radius: 0.25rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
  }
  
  .nav-dropdown-item {
    margin-bottom: 0.25rem;
  }
  
  .nav-dropdown-link {
    display: block;
    padding: 0.25rem 0;
    font-size: 14px;
    color: #333;
    transition: color 0.2s;
  }
  
  .nav-dropdown-link:hover {
    color: #337ab7;
  }


  b, strong {
    font-weight: 600;
  }
  
  code {
    border: none;
  }
  
  .shadow {
    box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.05);
  }
  
  .shadow-lg {
    box-shadow: 0px 15px 34px rgba(0, 0, 0, 0.1);
  }
  
  .bg-gradient-to-tl, .bg-gradient {
    background-image: linear-gradient(184.78deg, #fa709a 7.64%, #fee140 120.07%);
  }

  /* Section Style */
.section {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  
  /* Container */
  .container {
    max-width: 1202px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  
  /* Form Style */
  .form-inputs * {
    margin-bottom: 1.25rem;
    line-height: 2.5rem;
  }
  
  /* Social Icon Style */
  .social-icons {
    margin-left: -0.5rem;
    margin-top: -0.5rem;
  }
  
  .social-icons li {
    display: inline-block;
    margin: 0.5rem;
    line-height: 0;
  }
  
  .social-icons a {
    display: inline-flex;
    height: 2.75rem;
    width: 2.75rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    transition: all 0.2s;
  }
  
  .social-icons a:hover {
    background-color: #fc7f6f;
  }
  
  .social-icons a svg {
    margin: auto;
    height: 2.75rem;
  }
  
  .social-icons a path {
    transition: all 0.2s;
  }
  
  .social-icons a:hover path {
    fill: #fff;
  }
  
  /* Notice */
  .notice .notice-body p {
    margin-top: 0;
    margin-bottom: 0;
    color: #333;
  }
  
  .notice.note .notice-head {
    background-color: #fc7f6f;
  }
  
  .notice.note .notice-body {
    background-color: #fc7f6f;
    opacity: 0.25;
  }
  
  .notice.tip .notice-head {
    background-color: #73e273;
  }
  
  .notice.tip .notice-body {
    background-color: #73e273;
    opacity: 0.25;
  }
  
  .notice.info .notice-head {
    background-color: #ffc392;
  }
  
  .notice.info .notice-body {
    background-color: #f0b37e;
    opacity: 0.25;
  }
  
  .notice.warning .notice-head {
    background-color: #ff918c;
  }
  
  .notice.warning .notice-body {
    background-color: #ff918c;
    opacity: 0.25;
  }
  
  /* Tab Component */
  .tab-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    border-bottom: 1px solid #aaa;
  }
  
  .tab-nav-item {
    padding: 0.5rem 1rem;
    cursor: pointer;
    opacity: 0.8;
  }
  
  .tab-nav-item.active {
    border-bottom: 3px solid #aaa;
    opacity: 1;
  }
  
  .tab-content .tab-content-panel {
    display: none;
    padding: 1rem 0.5rem;
  }
  
  .tab-content .tab-content-panel.active {
    display: block;
  }
  
  /* Accordion */
  .accordion {
    background-color: #fff;
  }
  
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    font-size: 1.6rem;
  }
  
  .accordion-icon {
    width: 0.78em;
    height: 0.78em;
    transform: rotate(0deg);
    transition: transform 0.2s ease;
  }
  
  .accordion-content {
    max-height: 0;
    overflow: hidden;
    padding: 0 1rem;
  }
  
  .accordion.active .accordion-icon {
    transform: rotate(180deg);
  }
  
  .accordion.active .accordion-content {
    max-height: 100vh;
  }
  
  /* Form Style */
  .form-control, .form-select {
    height: 3.75rem;
    width: 100%;
    border-radius: 0.75rem;
    border: 1px solid #ccc;
    padding: 0.5rem;
    font-size: 1rem;
    color: #333;
    background-color: #fff;
    transition: border-color 0.2s, box-shadow 0.2s;
  }
  
  .form-control:focus, .form-select:focus {
    border-color: #fc7f6f;
    box-shadow: none;
  }
  
  .form-label {
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #333;
  }
  
  select:invalid {
    color: #b0b0b0 !important;
  }
  
  /* Content Style */
  .content {
    max-width: none;
  }
  
  .content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
    margin-top: 0.5rem;
    margin-bottom: 0.9rem;
    font-weight: 700;
  }





  .tab-nav-item {
    margin-bottom: 1rem;
    display: flex;
    border-radius: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
    color: #333;
    opacity: 1;
  }
  
  .tab-nav-item.active {
    border-bottom: none;
    background-color: rgba(255, 0, 0, 0.4);
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  
  /* Footer */
  .footer h6 {
    margin-bottom: 1rem;
    font-family: 'font-primary';
    font-weight: 600;
  }
  
  .footer p {
    font-size: 0.875rem;
  }
  
  .footer li > a {
    font-size: 0.875rem;
    color: black;
    text-decoration: none;
  }
  
  .footer li > a:hover {
    color: #fc7f6f;
    text-decoration: underline;
  }
  
  /* Gradient Number */
  .gradient-number span {
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'font-primary';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
  }
  
  /* Card */
  .card {
    position: relative;
    height: 100%;
    border-radius: 0.75rem;
    background-color: #fff;
    padding: 1.25rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  }
  
  .card-img {
    width: 100%;
  }
  
  .card-tags {
    position: absolute;
    right: 2.5rem;
    top: 2.5rem;
    margin-bottom: 1.5rem;
    z-index: 1;
  }
  
  .card-content {
    padding-top: 1.5rem;
  }
  
  .card-title {
    margin-bottom: 1rem;
    font-size: 1.1875rem;
  }
  
  .card-footer {
    border-top: 1px solid #ccc;
    padding-top: 1.5rem;
    padding-bottom: 0;
  }
  
  /* Tag */
  .tag {
    display: inline-block;
    height: 2rem;
    border-radius: 0.375rem;
    border: 1px solid #fc7f6f;
    background-color: #FAEBE1;
    padding-left: 0.875rem;
    padding-right: 0.875rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1;
    color: #fc7f6f;
    transition: all 0.2s;
  }
  
  .tag:hover {
    background-color: #fc7f6f;
    color: #fff;
  }
  
  /* Featured Posts */
  .featured-posts .card {
    display: flex;
    align-items: center;
  }
  
  .featured-posts .card-img {
    max-height: 210px;
    width: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
  }
  
  .featured-posts .card-title {
    font-size: 1.5rem;
  }
  
  .featured-posts .card-content {
    padding-top: 1.5rem;
    padding-left: 1.25rem;
  }
  
  .featured-posts .card-tags {
    position: relative;
    left: 0;
    top: 0;
  }
  
  .featured-posts .card-footer {
    border-top: none;
    padding-top: 0;
  }
  
  /* Filter List */
  .filter-list {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  
  .filter-btn {
    margin: 0.75rem;
    border-radius: 0.375rem;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: #333;
    background-color: #fff;
    transition: all 0.2s;
  }
  
  .filter-btn-active, .filter-btn.active {
    border: 1px solid #fff;
    background-color: #fff;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05);
    color: #333;
  }
  
  /* Career Single */
  .career-single-content h5 + p {
    margin-top: 1rem;
  }
  
  .career-single-content p + h5, .career-single-content ul + h5 {
    margin-top: 2rem;
  }
  
  .content {
    max-width: none;
  }
  
  .content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
    margin-top: 0.5rem;
    margin-bottom: 0.9rem;
    font-weight: 700;
    color: #333;
  }
  
  .content h1 {
    font-size: 1.875rem;
  }
  
  .content h2 {
    font-size: 1.5rem;
  }
  
  .content h3 {
    font-size: 1.375rem;
  }
  
  .content h4 {
    margin-bottom: 1.25rem;
  }
  
  .content h5 {
    margin-bottom: 0.625rem;
  }
  
  .content hr {
    border-color: #ccc;
  }
  
  .content p {
    font-size: 1rem;
    color: #333;
  }
  
  .content blockquote {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    border-left: none;
    font-style: normal;
  }
  
  .content code {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    color: #337ab7;
  }
  
  .content pre {
    border-radius: 0;
  }
  
  .content strong {
    color: #333;
  }
  
  .content a {
    color: #337ab7;
    text-decoration: none;
  }
  
  .content a:hover {
    text-decoration: underline;
  }
  
  .content li {
    color: #333;
  }
  
  .content li::marker {
    color: #333;
  }
  
  .content table {
    overflow: hidden;
    border: 1px solid #ccc;
  }
  
  .content thead {
    border-color: #ccc;
    background-color: #f9f9f9;
  }
  
  .content th {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .content tr {
    border-color: #ccc;
  }
  
  .content td {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .content h5 + p {
    margin-top: 0.625rem;
  }
  
  .content p + h5 {
    margin-top: 1.25rem;
  }
  
  .content p + h4, .content ul + h4 {
    margin-top: 2.5rem;
  }
  
  .content h4 + p, .content h4 + ul {
    margin-top: 1.25rem;
  }
  
  .key-feature-grid p {
    margin-top: 0.75rem;
    font-size: 0.875rem;
  }
  
  .key-feature-grid .icon {
    height: 1.5rem;
    width: 1.5rem;
  }
  
  .key-feature-grid .icon img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  
  .video {
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
  }
  
  .video-thumbnail {
    position: relative;
  }
  
  .video-play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    display: flex;
    height: 3.875rem;
    width: 3.875rem;
    transform: translateX(-50%) translateY(-50%);
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #337ab7;
    animation: shadow-pulse 1.5s infinite;
  }
  
  .video-play-btn::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    content: "";
    animation: shadow-pulse-2 1.4s infinite;
  }
  
  @keyframes shadow-pulse {
    0% {
      box-shadow: 0 0 0 0px rgba(254, 96, 25, 0.7);
    }
    70% {
      box-shadow: 0 0 0 35px rgba(254, 96, 25, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(254, 96, 25, 0);
    }
  }
  
  @keyframes shadow-pulse-2 {
    0% {
      box-shadow: 0 0 0 0px rgba(254, 96, 25, 0.7);
    }
    70% {
      box-shadow: 0 0 0 20px rgba(254, 96, 25, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(254, 96,25,0);
    }}

    .review {
        margin-top: 4rem;
        margin-bottom: 4rem;
        border-radius: 0.5rem;
        background-color: #fff;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        padding-top: 1.5rem;
        padding-bottom: 4rem;
        text-align: center;
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
      }
      
      .review-author-avatar {
        margin-left: auto;
        margin-right: auto;
        margin-top: -3rem;
        height: 4.5rem;
        width: 4.5rem;
        border-radius: 50%;
        padding: 0.125rem;
      }
      
      .reviews-carousel .swiper-pagination-bullet {
        height: 1.125rem;
        width: 1.125rem;
        background-color: #ccc;
      }
      
      .reviews-carousel .swiper-pagination-bullet-active {
        background-color: #337ab7;
      }
      
      .services .tab-nav {
        display: inline-block;
      }
      
      .services .tab-nav-item {
        margin-bottom: 1rem;
        display: flex;
        border-radius: 0.5rem;
        padding-top: 0;
        padding-bottom: 0;
        color: #333;
        opacity: 1;
      }
      
      .services .tab-nav-item.active {
        border-bottom: none;
        background-color: rgba(255, 0, 0, 0.4);
        padding-top: 0.875rem;
        padding-bottom: 0.875rem;
      }